@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;400&family=Open+Sans:wght@300;400;600;700;800&family=Poppins:wght@400;500&family=Quicksand:wght@300&family=Urbanist:wght@200;300;400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #fff;
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
